import { Fragment } from 'react';
import { ChildContainer, RootContainer } from './styled';
import { Link } from 'components/common';

const getTableConfig = name => ({
  headers: [
    {
      label: (
        <>
          <RootContainer>
            <div className='circle' />
          </RootContainer>
          <div style={{ fontSize: '15px' }}>{name}</div>
        </>
      ),
      key: 'superOrganisationName',
    },
    { label: 'City', key: 'superOrganisationCity' },
    { label: 'State', key: 'superOrganisationState' },
    { label: 'Country', key: 'superOrganisationCountry' },
  ],
  createRow: (r, i) => (
    <Fragment key={r.superOrganisationId}>
      <div className='test'>
        <ChildContainer
          className='child'
          level={r.level}
          increment={r.increment}
          hasParentSibling={r.hasParentSibling}
        >
          <div className='horizontal-line' />
          <div className='vertical-line' />
          <div className='circle'></div>
        </ChildContainer>
        <Link to={`/organisation/${r.superOrganisationId}`} showIconHint>
          {r.superOrganisationName}
        </Link>
      </div>
      <div>{r.superOrganisationCity || '-'}</div>
      <div>{r.superOrganisationState || '-'}</div>
      <div>{r.superOrganisationCountry || '-'}</div>
    </Fragment>
  ),
});

export default getTableConfig;
