import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisationsCache } from 'selectors/organisation';
import { checkOrganisationsInIndexRequest } from 'actions/organisation';

function useOrganisationExists(orgId, onMount) {
  const onMountChecked = useRef(false);
  const dispatch = useDispatch();
  const organisationsCache = useSelector(getOrganisationsCache);
  const organisationInCache = organisationsCache[orgId];

  const checkIfExists = useCallback(() => {
    if (!orgId) {
      return;
    }

    if (organisationInCache) {
      return;
    }

    dispatch(checkOrganisationsInIndexRequest(orgId));
  }, [dispatch, orgId, organisationInCache]);

  useEffect(() => {
    if (onMount && !onMountChecked.current) {
      checkIfExists();
      onMountChecked.current = true;
    }
  }, [checkIfExists, onMount, orgId]);

  return [
    checkIfExists,
    organisationInCache?.exists,
    organisationInCache?.loading,
  ];
}

export default useOrganisationExists;
