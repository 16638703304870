import { cloneElement } from 'react';
import { Empty } from 'antd';

import { useWindowWidth } from 'hooks';
import { Loader } from 'components/common';
import { capitalise } from 'utils/search';

import Headers from './Headers';
import { Container, HiddenCellRow, MaxContentCell } from '../styled';
import { useState } from 'react';
import { isValidElement } from 'react';
import { useCallback } from 'react';
import { ResizeableRowItem, RowItem } from './RowItem';

const camelCase = header => {
  if (!header) return 'unknown';
  let string;
  if (header.label) {
    string = header.label;
  } else {
    string = header;
  }
  const pascal = string
    .toString()
    .split(' ')
    .map(x => capitalise(x.toLowerCase()))
    .join('');

  return pascal.substring(0, 1).toLowerCase() + pascal.substring(1);
};

const createNonArrayCells = (row, rowIndex, headers, dataTestId) => {
  const cellTestId = headers[0].testId
    ? `${dataTestId}-row-${rowIndex}-${headers[0].testId}`
    : `${dataTestId}-row-${rowIndex}-${camelCase(headers[0])}`;
  return cloneElement(row.props.children, {
    className: 'cell',
    key: rowIndex,
    'data-testid': cellTestId,
  });
};

const ResponsiveBaseTable = ({
  config,
  data = [],
  loading,
  className,
  'data-testid': dataTestId = 'table',
  style,
  cellPadding,
  ...rest
}) => {
  const { headers, createRow } = config;

  const [columns, setColumns] = useState(headers.length + 1);
  const hideExtraColumn = columns > headers.length;

  const hiddenChildren = useCallback(
    row =>
      row.props.children
        .filter(x => isValidElement(x))
        .filter((_, i) => i + 2 > columns)
        .map((c, i) => {
          const label = headers[i + columns - 1]?.label;
          return (
            <HiddenCellRow key={i}>
              <div className='cellTitle'>
                {label}
                {label ? ':' : ''}
              </div>
              {c}
            </HiddenCellRow>
          );
        }),
    [headers, columns]
  );

  const createCells = (row, rowIndex) => {
    if (Array.isArray(row.props.children)) {
      return row.props.children
        .filter(x => isValidElement(x))
        .filter((_, i) => i + 1 < columns)
        .map((c, j) => {
          const cellTestId = headers[j].testId
            ? `${dataTestId}-row-${rowIndex}-${headers[j].testId}`
            : `${dataTestId}-row-${rowIndex}-${camelCase(headers[j])}`;
          return (
            <div
              key={j}
              data-testid={cellTestId}
              className={'cell'}
              style={{
                gridRow: `${rowIndex * 2 + 2}`,
                gridColumn: `${j + (hideExtraColumn ? 1 : 2)}`,
              }}
            >
              {headers[j].maxContentWidth ? (
                <MaxContentCell maxContentWidth={headers[j].maxContentWidth}>
                  {c}
                </MaxContentCell>
              ) : (
                c
              )}
            </div>
          );
        });
    }
    return createNonArrayCells(row, rowIndex, headers, dataTestId);
  };

  function onColumnChange(columns) {
    setColumns(columns + 1);
  }

  return (
    <div className={className}>
      <Container
        columns={headers.length}
        cellPadding={cellPadding}
        style={{
          gridTemplateColumns: `${
            hideExtraColumn ? '' : '45px'
          } repeat(${columns - 1}, auto)`,
          ...style,
        }}
      >
        <Headers
          {...rest}
          headers={headers}
          onColumnChange={onColumnChange}
          loading={loading}
          hideExtraColumn={hideExtraColumn}
        />
        {!loading &&
          data.map((r, i) => {
            const row = createRow(r, i);
            const cells = createCells(row, i);
            const newRow = cloneElement(row, {
              ...row.props,
              children: cells,
            });
            return (
              <ResizeableRowItem
                key={i}
                index={i}
                dataTestId={dataTestId}
                hiddenChildren={hiddenChildren(row)}
                className={r.className}
              >
                {newRow}
              </ResizeableRowItem>
            );
          })}
      </Container>
    </div>
  );
};

const StandardBaseTable = ({
  config,
  data = [],
  loading,
  className,
  'data-testid': dataTestId = 'table',
  style,
  hideHeader,
  cellPadding,
  ...rest
}) => {
  const { headers, createRow } = config;

  const createCells = (row, rowIndex) => {
    if (Array.isArray(row.props.children)) {
      return row.props.children
        .filter(x => isValidElement(x))
        .map((c, j) => {
          const cellTestId = headers[j].testId
            ? `${dataTestId}-row-${rowIndex}-${headers[j].testId}`
            : `${dataTestId}-row-${rowIndex}-${camelCase(headers[j])}`;
          return cloneElement(c, {
            'data-testid': cellTestId,
            className: 'cell',
            key: j,
          });
        });
    }
    return createNonArrayCells(row, rowIndex, headers, dataTestId);
  };

  return (
    <div className={className}>
      <Container
        columns={headers.length}
        cellPadding={cellPadding}
        style={{
          ...style,
        }}
      >
        {!hideHeader && <Headers {...rest} headers={headers} />}
        {!loading &&
          data.map((r, i) => {
            const row = createRow(r, i);
            const cells = createCells(row, i);
            const newRow = cloneElement(row, {
              ...row.props,
              children: cells,
            });
            return (
              <RowItem
                key={i}
                index={i}
                dataTestId={dataTestId}
                className={r.className}
              >
                {newRow}
              </RowItem>
            );
          })}
      </Container>
    </div>
  );
};

const BaseTable = props => {
  const { config, data = [], loading, footer, loaderSize, hideHeader } = props;

  const { isMobile, isTablet } = useWindowWidth();
  const { headers } = config;

  const responsiveTable = headers?.every(x => x.minWidth) && !hideHeader;

  const cellPadding = isMobile
    ? '3px 3px'
    : isTablet
    ? '10px 5px'
    : '10px 15px';

  return (
    <>
      {responsiveTable ? (
        <ResponsiveBaseTable
          {...{
            ...props,
            cellPadding,
          }}
        />
      ) : (
        <StandardBaseTable
          {...{
            ...props,
            cellPadding,
          }}
        />
      )}
      {loading && <Loader size={loaderSize} />}
      {data.length > 0 && footer}
      {!data.length && !loading && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default BaseTable;
