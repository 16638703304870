import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'components/common';
import { colours } from 'utils/theme';

const Stats = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-bottom: ${p => p.theme.gutter};
  > div {
    flex: 1;
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

const SubsidiariesStatistics = ({ organisation, loading }) => {
  if (loading) return null;

  let totals = organisation.superOrganisationSubsidiaries;

  return (
    <Stats>
      <Statistic
        icon='networkWired'
        iconColor={colours.publications}
        title='Subsidiaries'
        value={totals ? totals.distinctSuperOrganisationsCount : 0}
      />
      <Statistic
        icon='users'
        iconColor={colours.posters}
        title='People'
        value={totals ? totals.distinctSuperResearchers : 0}
      />
      <Statistic
        icon='map'
        iconColor={colours.publications}
        title='Countries'
        value={totals ? totals.distinctCountries : 0}
      />
    </Stats>
  );
};

export default SubsidiariesStatistics;
