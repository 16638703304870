import * as FromSearch from 'reducers/organisation/selectors';

export const SLICE = 'organisation';

export const getOrganisationResearchers = orgId => state =>
  FromSearch.getOrganisationResearchers(state[SLICE], orgId);
export const getOrganisationResearchersLoading = orgId => state =>
  FromSearch.getOrganisationResearchersLoading(state[SLICE], orgId);
export const getOrganisationResearcherSearchError = orgId => state =>
  FromSearch.getOrganisationResearcherSearchError(state[SLICE], orgId);
export const getOrganisationWorkItemSearchError = orgId => state =>
  FromSearch.getOrganisationWorkItemSearchError(state[SLICE], orgId);
export const getOrganisationResearchersPageSize = orgId => state =>
  FromSearch.getOrganisationResearchersPageSize(state[SLICE], orgId);
export const getOrganisationResearchersPageNumber = orgId => state =>
  FromSearch.getOrganisationResearchersPageNumber(state[SLICE], orgId);
export const getOrganisationResearchersTotals = orgId => state =>
  FromSearch.getOrganisationResearchersTotals(state[SLICE], orgId);
export const getOrganisationClinicalTrialTotals = orgId => state =>
  FromSearch.getOrganisationClinicalTrialTotals(state[SLICE], orgId);
export const getOrganisationTradeShowTotals = orgId => state =>
  FromSearch.getOrganisationTradeShowTotals(state[SLICE], orgId);
export const getOrganisationWorkItemTotals = orgId => state =>
  FromSearch.getOrganisationWorkItemTotals(state[SLICE], orgId);
export const getOrganisationWorkItemHighlight = orgId => state =>
  FromSearch.getOrganisationWorkItemHighlight(state[SLICE], orgId);
export const getFiltersByGroupId = orgId => state =>
  FromSearch.getFiltersByGroupId(state[SLICE], orgId);
export const getFilter = (orgId, id) => state =>
  FromSearch.getFilter(state[SLICE], orgId, id);
export const getWorkItemCategoryFilter = (orgId, id) => state =>
  FromSearch.getWorkItemCategoryFilter(state[SLICE], orgId, id);
export const getWorkItemCategoryItemSearchFilter = (orgId, id) => state =>
  FromSearch.getWorkItemCategoryItemSearchFilter(state[SLICE], orgId, id);
export const getSort = (orgId, id) => state =>
  FromSearch.getSort(state[SLICE], orgId, id);

export const getOrganisationExhibitorData = orgId => state =>
  FromSearch.getOrganisationExhibitorData(state[SLICE], orgId);
export const getOrganisationLoadingExhibitorData = orgId => state =>
  FromSearch.getOrganisationLoadingExhibitorData(state[SLICE], orgId);

export const getOrganisationClinicalTrialData = (orgId, orgName) => state =>
  FromSearch.getOrganisationClinicalTrialData(state[SLICE], orgId, orgName);
export const getOrganisationLoadingClinicalTrialData = orgId => state =>
  FromSearch.getOrganisationLoadingClinicalTrialData(state[SLICE], orgId);
export const getOrganisationClinicalTrialPageNumber = orgId => state =>
  FromSearch.getOrganisationClinicalTrialPageNumber(state[SLICE], orgId);
export const getOrganisationClinicalTrialPageSize = orgId => state =>
  FromSearch.getOrganisationClinicalTrialPageSize(state[SLICE], orgId);
export const getOrganisationClinicalTrialSort = orgId => state =>
  FromSearch.getOrganisationClinicalTrialSort(state[SLICE], orgId);
export const getOrganisationClinicalTrialFilter = (orgId, id) => state =>
  FromSearch.getOrganisationClinicalTrialFilter(state[SLICE], orgId, id);
export const getOrganisationClinicalTrialFilters = (orgId, id) => state =>
  FromSearch.getOrganisationClinicalTrialFilters(state[SLICE], orgId, id);
export const getHasValidClinicalTrialSearchQuery = orgId => state =>
  FromSearch.getHasValidClinicalTrialSearchQuery(state[SLICE], orgId);
export const getOrganisationClinicalTrialsIsLoading = orgId => state =>
  FromSearch.getOrganisationClinicalTrialsIsLoading(state[SLICE], orgId);
export const getBlockedForClinicalTrialExport = orgId => state =>
  FromSearch.getBlockedForClinicalTrialExport(state[SLICE], orgId);
export const getOrganisationClinicalTrialSearchError = orgId => state =>
  FromSearch.getOrganisationClinicalTrialSearchError(state[SLICE], orgId);

export const getExhibitorTableSort = state => state[SLICE].tableSort;
export const getExhibitorFilters = state => state[SLICE].filters;
export const getExhibitorRestoringState = state => state[SLICE].restoringState;
export const getOrganisationView = state =>
  FromSearch.getOrganisationView(state[SLICE]);

export const getHasFilters = (keys, orgId) => state =>
  FromSearch.getHasFilters(state[SLICE], orgId, keys);

export const getPurchasingSearchRequest = orgId => state =>
  FromSearch.getPurchasingSearchRequest(state[SLICE], orgId);

export const getPurchasingFilter = (id, group = false) => state =>
  FromSearch.getPurchasingFilter(state[SLICE], id, group);

export const getPurchasingFilters = state =>
  FromSearch.getPurchasingFilters(state[SLICE]);
export const getPurchasingPageSize = state =>
  FromSearch.getPurchasingPageSize(state[SLICE]);
export const getPurchasingPageNumber = state =>
  FromSearch.getPurchasingPageNumber(state[SLICE]);
export const getPurchasingSort = state =>
  FromSearch.getPurchasingSort(state[SLICE]);
export const getPurchasingSortDir = state =>
  FromSearch.getPurchasingSortDir(state[SLICE]);
export const getLastSuccessfulPurchasingQuery = state =>
  FromSearch.getLastSuccessfulPurchasingQuery(state[SLICE]);
export const getPurchasingLoading = state =>
  FromSearch.getPurchasingLoading(state[SLICE]);
export const getShowNotice = state => FromSearch.getShowNotice(state[SLICE]);
export const getShowClinicalTrialNotice = state =>
  FromSearch.getShowClinicalTrialNotice(state[SLICE]);

export const getOrganisationWorkItemPageNumber = orgId => state =>
  FromSearch.getOrganisationWorkItemPageNumber(state[SLICE], orgId);
export const getOrganisationWorkItemPageSize = orgId => state =>
  FromSearch.getOrganisationWorkItemPageSize(state[SLICE], orgId);
export const getOrganisationWorkItemSort = orgId => state =>
  FromSearch.getOrganisationWorkItemSort(state[SLICE], orgId);
export const getOrganisationWorkItemIsLoading = orgId => state =>
  FromSearch.getOrganisationWorkItemIsLoading(state[SLICE], orgId);
export const getOrganisationWorkItems = orgId => state =>
  FromSearch.getOrganisationWorkItems(state[SLICE], orgId);
export const getOrganisationWorkItemFilter = (orgId, id) => state =>
  FromSearch.getOrganisationWorkItemFilter(state[SLICE], orgId, id);
export const getOrganisationWorkItemFilters = orgId => state =>
  FromSearch.getOrganisationWorkItemFilters(state[SLICE], orgId);
export const getBlockedForWorkItemExport = orgId => state =>
  FromSearch.getBlockedForWorkItemExport(state[SLICE], orgId);
export const getBlockedForResearchersExport = orgId => state =>
  FromSearch.getBlockedForResearchersExport(state[SLICE], orgId);
export const getHasValidQuery = orgId => state =>
  FromSearch.getHasValidQuery(state[SLICE], orgId);
export const getHasValidItemSearchQuery = orgId => state =>
  FromSearch.getHasValidItemSearchQuery(state[SLICE], orgId);
export const getOrganisationToCheckInIndex = state =>
  FromSearch.getOrganisationToCheckInIndex(state[SLICE]);
export const getOrganisationsCache = state =>
  FromSearch.getOrganisationsCache(state[SLICE]);
