import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganisationToCheckInIndex,
  getOrganisationsCache,
} from 'selectors/organisation';
import { superOrganisationsExists } from 'services/api';
import { usePost } from 'hooks';
import {
  checkOrganisationsInIndexLoading,
  checkOrganisationsInIndexSuccess,
} from 'actions/organisation';

function useCheckOrganisationsInIndex(defaultIdsToCheck) {
  const [{ res }, fetchSuperOrgsExists] = usePost({
    url: superOrganisationsExists,
    onMount: false,
  });
  const dispatch = useDispatch();
  const organisationsCache = useSelector(getOrganisationsCache);
  const organisationToCheckInIndex = useSelector(getOrganisationToCheckInIndex);

  useEffect(() => {}, [organisationsCache]);

  useEffect(() => {
    if (organisationToCheckInIndex) {
      let ids = [
        ...new Set([...defaultIdsToCheck, organisationToCheckInIndex]),
      ].filter(x => x > 0);
      let orgIdsToCheck = ids.filter(id => !organisationsCache[id]);
      if (orgIdsToCheck.some(x => x)) {
        dispatch(checkOrganisationsInIndexLoading(orgIdsToCheck));
        fetchSuperOrgsExists(orgIdsToCheck);
      }
    }
  }, [
    defaultIdsToCheck,
    dispatch,
    fetchSuperOrgsExists,
    organisationToCheckInIndex,
    organisationsCache,
  ]);

  useEffect(() => {
    if (res) {
      dispatch(checkOrganisationsInIndexSuccess(res));
    }
  }, [dispatch, res]);
}

export default useCheckOrganisationsInIndex;
