import styled from 'styled-components';

const rootMargin = 10;
const childrenMargin = 20;
const horizontalLineWidth = 20;
const dynamicStyles = {};
for (let i = 0; i < 15; i++) {
  dynamicStyles[`.level-${i} > div:first-child`] = {
    marginLeft: `${childrenMargin + horizontalLineWidth * i}px`,
    position: 'relative',
  };
  dynamicStyles[`.level-${i} > .cell:first-child`] = {
    overflow: 'visible',
  };
}
export const Container = styled.div`
  .subsidiaries-table .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .subsidiaries-table .cell {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  ${dynamicStyles}
`;
export const RootContainer = styled.div`
  position: absolute;
  top: 0px;
  left: -${rootMargin}px;
  height: 100%;
  .circle {
    position: absolute;
    top: 50%;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    border: 2px solid gray;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 2;
  }
  .line {
    position: absolute;
    top: calc(50% + 8px);
    height: calc(50% - 8px);
    width: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translate(-50%, 0);
  }
`;

export const ChildContainer = styled.div`
  position: absolute;
  top: 0;
  left: -${rootMargin + childrenMargin}px;
  height: 100%;
  .horizontal-line {
    width: ${horizontalLineWidth}px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .vertical-line {
    height: ${props =>
      `calc(${100 + props.increment * 100}% + ${props.increment + 1}px)`};
    width: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: ${props =>
      `calc(${-50 - props.increment * 100}% - ${props.increment}px)`};
    transform: translate(-50%, 0);
  }
  .circle {
    position: absolute;
    top: 50%;
    left: ${horizontalLineWidth}px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: gray;
    transform: translate(-50%, -50%);
  }
  .root-line {
    height: 100%;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: ${props => `${-props.level * horizontalLineWidth}px`};
    transform: translate(-50%, 0);
  }
`;
