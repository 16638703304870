import React, { useMemo } from 'react';
import { Table } from 'components/common';
import getTableConfig from './tableConfig';
import { Container } from './styled';

const getData = (organisation, level = 0) => {
  let data = [];
  let childrenData = [];
  let prevChildrenLength = 0;
  for (let i = 0; i < organisation.subsidiaries?.length; i++) {
    for (let m = 0; m < childrenData.length; m++) {
      childrenData[m].hasParentSibling = true;
    }
    data.push({
      ...organisation.subsidiaries[i],
      level,
      className: `level-${level}`,
      increment: prevChildrenLength,
    });
    childrenData = getData(organisation.subsidiaries[i], level + 1);
    prevChildrenLength = childrenData.length;
    data = [...data, ...childrenData];
  }
  return data;
};

function OrganisationSubsidiaries({ organisation, style = {} }) {
  const tableConfig = getTableConfig(organisation.organisationName);
  const data = useMemo(
    () => getData(organisation.superOrganisationSubsidiaries),
    [organisation.superOrganisationSubsidiaries]
  );

  return (
    <Container style={style}>
      <Table
        config={tableConfig}
        data={data}
        className={'subsidiaries-table'}
        paginate
      />
    </Container>
  );
}

OrganisationSubsidiaries.propTypes = {};

export default OrganisationSubsidiaries;
