import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Tooltip, Spin } from 'components/common';
import useOrganisationExists from './useOrganisationExists';

import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    margin-left: 3px;
  }
  .ant-spin {
    height: 20px;
  }
`;

export function SubsidiaryValue({
  superOrganisationParentId,
  superOrganisationParentName,
  exists,
  loading,
}) {
  return (
    <TitleContainer>
      Subsidiary of
      {exists ? (
        <Link
          to={`/organisation/${superOrganisationParentId}`}
          target='_blank'
          showIconHint
        >
          {superOrganisationParentName}
        </Link>
      ) : (
        <div>
          {superOrganisationParentName}
          {loading && (
            <Spin
              style={{ marginLeft: '3px' }}
              indicator={<LoadingOutlined spin />}
            />
          )}
        </div>
      )}
    </TitleContainer>
  );
}

const Container = styled.div`
  svg: hover {
    color: ${p => p.theme.teal};
  }
`;
function SubsidiaryIcon({
  superOrganisationParentId,
  superOrganisationParentName,
  style,
}) {
  const [checkIfExists, exists, loading] = useOrganisationExists(
    superOrganisationParentId
  );
  if (!superOrganisationParentId) {
    return null;
  }

  const handleOpen = visible => {
    if (visible) {
      checkIfExists();
    }
  };

  return (
    <Tooltip
      title={
        <SubsidiaryValue
          superOrganisationParentId={superOrganisationParentId}
          superOrganisationParentName={superOrganisationParentName}
          exists={exists}
          loading={loading}
        />
      }
      overlayStyle={{ maxWidth: '500px' }}
      onOpenChange={handleOpen}
    >
      <Container style={style}>
        <Icon icon='networkWired' size='sm' color='#bfbfbf' />
      </Container>
    </Tooltip>
  );
}

SubsidiaryIcon.propTypes = {
  superOrganisationParentId: PropTypes.number,
  superOrganisationParentName: PropTypes.string,
};

export default SubsidiaryIcon;
