import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Loader, Link, ResearcherEmail } from 'components/common';
import { getProject } from 'services/api';
import { format } from 'utils/date';
import { formatCurrency } from 'utils/number';
import { useAuth, usePost } from 'hooks';
import { getResearcherText } from 'utils/constants';

import { Container, Pane, Detail, Row } from './styled';
import CTA from './CTA';

const Project = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [{ res: project, loading }] = usePost({
    url: getProject(id),
    onMount: true,
  });

  useEffect(() => {
    if (project && project.title) {
      const titleAsArray = project.title.split(' ');
      const firstThree = titleAsArray.slice(0, 3);
      const title =
        titleAsArray.length > 3 ? `${firstThree.join(' ')}...` : project.title;
      document.title = `${title} | SciLeads`;
    }
  }, [project]);

  if (loading)
    return (
      <Container>
        <Row>
          <Pane>
            <Loader />
          </Pane>
        </Row>
        <Row>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
        </Row>
      </Container>
    );

  if (project.showCta) {
    return <CTA />;
  }
  return (
    <Container>
      <Row>
        <Pane title='Project Details'>
          <Detail label='Title'>{project.title}</Detail>
          <Detail label='Description'>{project.description}</Detail>
          <Detail label='Keywords'>{project.keywords}</Detail>
          <div style={{ display: 'inline-flex' }}>
            <Detail
              style={{ marginRight: '15px', marginBottom: '0' }}
              label='Date Added'
            >
              {format(project.dateAdded)}
            </Detail>
            <Detail label='Date Updated'>{format(project.dateUpdated)}</Detail>
          </div>
          <Detail label='Public Health Relevance'>
            {project.publicHealthRelevance}
          </Detail>
        </Pane>
      </Row>
      <Row>
        <Pane title='Funding Details'>
          <Detail label='Date Awarded'>{format(project.dateAwarded)}</Detail>
          <Detail label={`Funding (${project.currency})`}>
            {formatCurrency(project.fundingAmount)}
          </Detail>
          {!!project.fundingAmountUsd && (
            <Detail label='Funding (USD)'>
              {formatCurrency(project.fundingAmountUsd)}
            </Detail>
          )}
          <Detail label='Project Number'>{project.projectNumber}</Detail>
          <Detail label='Funding Starts'>
            {format(project.fundingStarts)}
          </Detail>
          <Detail label='Funding Ends'>{format(project.fundingEnds)}</Detail>
          <Detail label='Funding Source'>{project.fundingSource}</Detail>
        </Pane>
        <Pane title={`${getResearcherText({ capitalize: true })} Details`}>
          <Detail label='Name'>
            <Link to={`/researcher/${project.sciLeadsSuperResearcherId}`}>
              {project.firstName} {project.lastName}
            </Link>
          </Detail>
          {!user.hideResearcherEmail && (
            <Detail label='Email'>
              <ResearcherEmail
                email={project.email}
                emailStatusCategory={project.emailStatus}
                hideIcon
              />
            </Detail>
          )}
        </Pane>
        <Pane title='Organisation Details'>
          <Detail label='Name'>{project.organisation}</Detail>
          <Detail label='Address'>{project.address}</Detail>
          <Detail label='City'>{project.city}</Detail>
          <Detail label='State'>{project.state}</Detail>
          <Detail label='Postcode'>{project.postcode}</Detail>
          <Detail label='Country'>{project.country}</Detail>
        </Pane>
      </Row>
    </Container>
  );
};

export default Project;
